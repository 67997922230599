.Footer {
  padding: 2rem 0;
}

.Footer-logo {
  display: block;
  margin: 1rem auto;
  width: 50%;
  max-width: 15rem;
}

@media screen and (min-width: 800px) {
  .Footer-logo {
    width: 15%;
  }
}
