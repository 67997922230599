.Nav {
  display: none;
}

@media screen and (min-width: 800px) {
  .Nav {
    display: block;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    padding: 0 0.5rem;
    z-index: 1;
    opacity: 0.3;
    transition: all 0.3s;
  }
}

.Nav:hover {
  transform: translate(0, -50%);
  opacity: 1;
}

.Nav-box {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 3rem;
  padding: 0.5rem 0;
  background: #1E88E5;
  border-radius: 1.5rem;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0.2rem 0;
  background: #0D47A1;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
}

.Nav-link:hover {
  animation: Nav-link-hover 0.5s ease-in both;
}

@keyframes Nav-link-hover {
  0%, 100% {
    transform: rotate(0);
  }

  25%, 75% {
    transform: rotate(10deg);
  }

  75% {
    transform: rotate(-10deg);
  }
}
