* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.block-wrapper {
  margin: 5rem auto;
  width: 80%;
  max-width: 1080px;
}

.block-header {
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-family: 'Ubuntu', sans-serif;
  color: #CFD8DC;
}

.inner-wrapper {
  padding: 2rem;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}
