* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100vh;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.block-wrapper {
  margin: 5rem auto;
  width: 80%;
  max-width: 1080px;
}

.block-header {
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-family: 'Ubuntu', sans-serif;
  color: #CFD8DC;
}

.inner-wrapper {
  padding: 2rem;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

.MainHead {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MainHead-logo-wrapper {
  position: relative;
  top: 0;
  left: 0;
  margin: 2rem 0;
  width: 80%;
  pointer-events: none;
}

.MainHead-logo {
  width: 50%;
}

.MainHead-main-text {
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-size: 3rem;
  font-family: 'Raleway', sans-serif;
  font-weight: lighter;
  color: white;
  pointer-events: none;
}

.TextCircuit {
    display: inline-block;
    width: 4em;
    pointer-events: none;
}

.MainBackground {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.About {
}

.About-wrapper {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .About-wrapper {
    flex-direction: row;
  }
}

.About-img {
  display: block;
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 800px) {
  .About-img {
    width: 30%;
    height: auto;
  }
}

.About-text {
  padding: 2rem;
  font-size: 1.0rem;
  line-height: 1.5rem;
  font-family: 'Ubuntu', sans-serif;
}

.About-text-section {
  padding: 1rem 0;
}

.Skill {}

.Skill-block-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.Skill-block {
  margin: 0.5rem 0;
  width: 32%;
  min-width: 13em;
}

.Skill-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3) inset;
}

.Skill-logo {
  width: 2rem;
  height: 2rem;
}

.Skill-level-text {
  margin: 0 auto;
  padding: 1rem 0;
  width: 80%;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.3rem;
}

.Skill-list-wrapper {
  text-align: center;
}

.Skill-list {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.Skill-list > li {
  display: inline;
  margin: 0.3rem 0;
  font-size: 1.1rem;
  font-family: 'Raleway', sans-serif;
}

.Works {}

.Works-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  width: 100%;
}


@media screen and (min-width: 800px) {
  .Works-item {
    flex-direction: row;
  }
}

.Works-img-wrapper {
  position: relative;
  width: 100%;
  border-radius: 3px;
}

.Works-img {
  display: block;
  width: 100%;
  border-radius: 3px;
}

.Works-text {
  margin: 1rem;
  padding: 2rem;
  width: 100%;
}

.Works-title {
  margin: 0 0 1rem;
  padding: 0 1rem;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'Raleway', sans-serif;
}


.Works-url {
  width: 100%;
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
  font-family: 'Raleway', sans-serif;
}

.Form {
  position: relative;
  margin: auto;
  padding: 2rem 3rem;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .Form {
    width: 70%;
  }
}

.Form-parts {
  margin: 1rem auto;
}

.Form-mail-input-label {
  display: block;
  color: #607D8B;
}

.Form-mail-input-text {
  margin: 0;
  padding: 0.2rem 0.5rem;
  display: block;
  font-size: 1.1rem;
  font-family: 'Ubuntu', sans-serif;
}

.Form-mail-input {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #CFD8DC;
  outline: 0;
  border-radius: 3px;
  font-family: 'Ubuntu', sans-serif;
  transition: all 0.3s;
}

.Form-mail-input:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Form-message-input {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #CFD8DC;
  outline: 0;
  border-radius: 3px;
  resize: vertical;
  font-family: 'Ubuntu', sans-serif;
  transition: all 0.3s;
}

.Form-message-input:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Form-send-error {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0.5rem;
  width: 100%;
  border-radius: 3px;
  color: white;
  background: #D32F2F;
}

.Form-send-error-icon {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.Form-send-error-text {
  font-size: 1rem;
}

.Form-send-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 0;
  border-radius: 3px;
  outline: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  background: #0D47A1;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.Form-send-button:hover {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.Form-send-button:active {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5) inset;
}

.Form-send-button:disabled {
  background: #BBDEFB;
  cursor: default;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0);
}

.Form-sending-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: #0D47A1;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Form-sending-text {
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

.Form-sending-icon {
  font-size: 5rem;
}

.Contacts {
  width: 100%;
}

.Contacts-form {
  margin-bottom: 5rem;
}

.Contacts-link-before-text {
  width: 100%;
  font-size: 1.1rem;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
}

.Contacts-link-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Contacts-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  color: white;
  background: #0277BD;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.Contacts-link:hover {
  animation: Contacts-link-hover 0.5s ease-in both;
}

@keyframes Contacts-link-hover {
  0%, 50%, 100% {
    transform: translateY(0) rotate(0);
  }

  25%, 75% {
    transform: translateY(-3px) rotate(5deg);
  }
}

.Footer {
  padding: 2rem 0;
}

.Footer-logo {
  display: block;
  margin: 1rem auto;
  width: 50%;
  max-width: 15rem;
}

@media screen and (min-width: 800px) {
  .Footer-logo {
    width: 15%;
  }
}

.Nav {
  display: none;
}

@media screen and (min-width: 800px) {
  .Nav {
    display: block;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    padding: 0 0.5rem;
    z-index: 1;
    opacity: 0.3;
    transition: all 0.3s;
  }
}

.Nav:hover {
  transform: translate(0, -50%);
  opacity: 1;
}

.Nav-box {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 3rem;
  padding: 0.5rem 0;
  background: #1E88E5;
  border-radius: 1.5rem;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0.2rem 0;
  background: #0D47A1;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
}

.Nav-link:hover {
  animation: Nav-link-hover 0.5s ease-in both;
}

@keyframes Nav-link-hover {
  0%, 100% {
    transform: rotate(0);
  }

  25%, 75% {
    transform: rotate(10deg);
  }

  75% {
    transform: rotate(-10deg);
  }
}

