.Skill {}

.Skill-block-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.Skill-block {
  margin: 0.5rem 0;
  width: 32%;
  min-width: 13em;
}

.Skill-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3) inset;
}

.Skill-logo {
  width: 2rem;
  height: 2rem;
}

.Skill-level-text {
  margin: 0 auto;
  padding: 1rem 0;
  width: 80%;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.3rem;
}

.Skill-list-wrapper {
  text-align: center;
}

.Skill-list {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.Skill-list > li {
  display: inline;
  margin: 0.3rem 0;
  font-size: 1.1rem;
  font-family: 'Raleway', sans-serif;
}
