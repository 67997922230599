.MainHead {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MainHead-logo-wrapper {
  position: relative;
  top: 0;
  left: 0;
  margin: 2rem 0;
  width: 80%;
  pointer-events: none;
}

.MainHead-logo {
  width: 50%;
}

.MainHead-main-text {
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 80%;
  text-align: center;
  font-size: 3rem;
  font-family: 'Raleway', sans-serif;
  font-weight: lighter;
  color: white;
  pointer-events: none;
}
