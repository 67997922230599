.Contacts {
  width: 100%;
}

.Contacts-form {
  margin-bottom: 5rem;
}

.Contacts-link-before-text {
  width: 100%;
  font-size: 1.1rem;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
}

.Contacts-link-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Contacts-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  color: white;
  background: #0277BD;
  height: 4rem;
  width: 4rem;
  font-size: 2rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.Contacts-link:hover {
  animation: Contacts-link-hover 0.5s ease-in both;
}

@keyframes Contacts-link-hover {
  0%, 50%, 100% {
    transform: translateY(0) rotate(0);
  }

  25%, 75% {
    transform: translateY(-3px) rotate(5deg);
  }
}
