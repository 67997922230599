.Works {}

.Works-item {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  width: 100%;
}


@media screen and (min-width: 800px) {
  .Works-item {
    flex-direction: row;
  }
}

.Works-img-wrapper {
  position: relative;
  width: 100%;
  border-radius: 3px;
}

.Works-img {
  display: block;
  width: 100%;
  border-radius: 3px;
}

.Works-text {
  margin: 1rem;
  padding: 2rem;
  width: 100%;
}

.Works-title {
  margin: 0 0 1rem;
  padding: 0 1rem;
  width: 100%;
  font-size: 1.5rem;
  font-family: 'Raleway', sans-serif;
}


.Works-url {
  width: 100%;
  color: black;
  text-decoration: none;
  font-size: 0.9rem;
  font-family: 'Raleway', sans-serif;
}
