.Form {
  position: relative;
  margin: auto;
  padding: 2rem 3rem;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .Form {
    width: 70%;
  }
}

.Form-parts {
  margin: 1rem auto;
}

.Form-mail-input-label {
  display: block;
  color: #607D8B;
}

.Form-mail-input-text {
  margin: 0;
  padding: 0.2rem 0.5rem;
  display: block;
  font-size: 1.1rem;
  font-family: 'Ubuntu', sans-serif;
}

.Form-mail-input {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #CFD8DC;
  outline: 0;
  border-radius: 3px;
  font-family: 'Ubuntu', sans-serif;
  transition: all 0.3s;
}

.Form-mail-input:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Form-message-input {
  display: block;
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #CFD8DC;
  outline: 0;
  border-radius: 3px;
  resize: vertical;
  font-family: 'Ubuntu', sans-serif;
  transition: all 0.3s;
}

.Form-message-input:focus {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Form-send-error {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding: 1rem 0.5rem;
  width: 100%;
  border-radius: 3px;
  color: white;
  background: #D32F2F;
}

.Form-send-error-icon {
  margin-right: 1rem;
  font-size: 1.2rem;
}

.Form-send-error-text {
  font-size: 1rem;
}

.Form-send-button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 0;
  border-radius: 3px;
  outline: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  background: #0D47A1;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.Form-send-button:hover {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.Form-send-button:active {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5) inset;
}

.Form-send-button:disabled {
  background: #BBDEFB;
  cursor: default;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0);
}

.Form-sending-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: #0D47A1;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.Form-sending-text {
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1.5rem;
  text-align: center;
}

.Form-sending-icon {
  font-size: 5rem;
}
