.About {
}

.About-wrapper {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .About-wrapper {
    flex-direction: row;
  }
}

.About-img {
  display: block;
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 800px) {
  .About-img {
    width: 30%;
    height: auto;
  }
}

.About-text {
  padding: 2rem;
  font-size: 1.0rem;
  line-height: 1.5rem;
  font-family: 'Ubuntu', sans-serif;
}

.About-text-section {
  padding: 1rem 0;
}
